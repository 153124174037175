import { render, staticRenderFns } from "./riskEvent.vue?vue&type=template&id=37aa2dcd&scoped=true&"
import script from "./riskEvent.vue?vue&type=script&lang=js&"
export * from "./riskEvent.vue?vue&type=script&lang=js&"
import style0 from "./riskEvent.vue?vue&type=style&index=0&id=37aa2dcd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37aa2dcd",
  null
  
)

export default component.exports