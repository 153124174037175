<template>
    <el-card class="riskBox">
        <div class="tabs">
            <div class="menuTabs">
                <div v-for="item in RISKTYPE" :key="item.value" :class="['tabItem', tabPosition === item.value ? 'active-tab' : '']" @click="handleClick(item.value)">
                    {{ item.label }}
                </div>
            </div>
        </div>
        <!-- 搜索 -->
        <div class="search-box">
            <el-form ref="searchForm" inline>
                <el-form-item label="所属组织">
                    <el-cascader
                        clearable
                        placeholder="请选择所属组织"
                        v-model="streetArr"
                        :props="defaultProps"
                        class="w300"
                        ref="supervisedUnitCode"
                        :show-all-levels="false"
                        filterable
                        :options="streetList"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="关键字">
                    <el-input v-model="keyWord" clearable class="w300" placeholder="请输入姓名/报警地址"></el-input>
                </el-form-item>
                <el-form-item label="报警日期">
                    <el-date-picker
                        v-model="timeRange"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        clearable
                        class="w300"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="处理状态">
                    <el-select v-model="status" placeholder="请选择处理状态" class="w300" clearable>
                        <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="searchList">搜索</el-button>
                    <el-button type="primary" plain @click="downLoadTable">导出</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table
            ref="table"
            v-loading="loading"
            class="table"
            :data="tableData"
            border
            :header-cell-style="headerCellStyle"
            @row-click="toogleExpand"
            @sort-change="sortChangeInit"
        >
            <template slot="empty">
                <IsEmpty />
            </template>
            <!-- <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form inline class="demo-table-expand">
                        <Map class="map" :longitude="props.row.lon" :latitude="props.row.lat" />
                    </el-form>
                </template>
            </el-table-column> -->
            <el-table-column prop="date" label="序号" type="index" :index="count" width="50" align="center" />
            <el-table-column v-if="show_tabs" prop="date" label="事件类型" width="180" align="center">
                <template slot-scope="scope">
                    <div class="date-box">
                        <div class="date-txt">
                            {{ getContent(RISKTYPE, scope.row.eventType, 'label', 'text') }}
                            <span v-if="scope.row.secondaryName">{{ `（${scope.row.secondaryName}）` }}</span>
                        </div>
                        <div v-if="scope.row.eventTimes >= 2" class="num-box">
                            <i>x</i>
                            <span>{{ scope.row.eventTimes }}</span>
                        </div>
                    </div>
                </template> </el-table-column
            >``
            <el-table-column label="处理状态" width="140" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.processStatus === 2" style="color: #999999" class="processStatus">
                        <img class="chu_img" src="../../assets/imgs/gou_green.png" alt />已处理
                    </div>
                    <div v-else-if="scope.row.processStatus === 0 || scope.row.processStatus === -1 || scope.row.processStatus === 1" style="color: #fe0303" class="processStatus">
                        <img class="chu_img" src="../../assets/imgs/weichuli.png" alt />正在处理
                    </div>
                    <!-- <div v-else-if="scope.row.processStatus === -1" style="color: #fe0303" class="processStatus">
                        <img class="chu_img" src="../../assets/imgs/weichuli.png" alt />正在处理
                    </div>
                    <div v-else-if="scope.row.processStatus === 1" style="color: #3fcf89" class="processStatus">
                        <img class="chu_img" src="../../assets/imgs/yichuli.png" alt />正在处理
                    </div> -->
                    <div v-else-if="scope.row.processStatus === 3" style="color: #999999" class="processStatus">
                        <el-tooltip class="item" effect="light" content="老人状态安全！" placement="top-end" transition="liner" :enterable="false" :hide-after="1000">
                            <div class="processStatus"><img class="chu_img" src="../../assets/imgs/gou_green.png" alt />已处理</div>
                        </el-tooltip>
                    </div>
                    <div v-else-if="scope.row.processStatus === -2" style="color: #ffb246" class="processStatus">
                        <img class="chu_img" src="../../assets/imgs/chaoshi.png" alt />超时未处理
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="eqWarnReason" label="设备预警原因" width="140" align="center" />
            <el-table-column prop="eventReason" label="事件发生原因" width="140" align="center" />
            <el-table-column prop="alertTime" label="预警时间" width="160" sortable="custom" align="center" />
            <el-table-column prop="position" label="报警地址" width="180" :show-overflow-tooltip="true" align="center" />
            <el-table-column prop="organizationName" label="所属组织" width="180" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ `${scope.row.streetName}/${scope.row.organizationName}` }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="userName" label="客户姓名" width="100" align="center" />
            <el-table-column label="性别" width="100" align="center">
                <template slot-scope="scope">
                    <span>{{ getContent(SEXLIST, scope.row.sex, 'label', 'text') }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="phone" label="电话" width="120" align="center" />
            <el-table-column prop="responder" label="协助人员" width="220" align="center">
                <template slot-scope="scope">
                    <div v-for="(item, index) in scope.row.responderList" :key="index">
                        {{ `${item}:${scope.row.responderPhoneList[index]}` }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="processDetails" label="备注" width="120" align="center" />
            <el-table-column prop="responseTime" label="响应时长" width="120" sortable="custom" align="center" />
            <el-table-column label="操作" fixed="right" min-width="180" align="center">
                <template slot-scope="scope">
                    <el-popover placement="right" trigger="hover">
                        <detail :id="scope.row.id" :key="scope.row.id" />
                        <el-button slot="reference" type="text">通知进度</el-button>
                    </el-popover>
                    <el-button style="margin-left: 10px" type="text" @click.prevent="seeLog(scope.row)">处理日志</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            style="margin: 20px 0"
            background
            layout="total, sizes, prev, pager, next"
            :total="total"
            :page-size="pageSize"
            :page-sizes="[10, 20, 30, 40, 100]"
            :current-page="pageNum"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
        <!-- 风险日志 -->
        <RiskLog v-if="showLog" :showLog.sync="showLog" :riskId="riskId" />
    </el-card>
</template>

<script>
import Map from './components/map.vue'
import Detail from './components/detail.vue'
import { selectRiskEventList, riskEventListPageDown } from '../../api/homeSave'
import { downloadStream } from '@/utils/index'
import { getContent } from '@/utils/index'
import { RISKTYPE, SEXLIST } from '../../data/dataDictionary'
import { streetList } from '@/api/orderManage'
import RiskLog from './components/riskLog.vue'
export default {
    // 风险事件
    name: 'RiskEvent',
    components: {
        Map,
        Detail,
        RiskLog
    },
    data() {
        return {
            SEXLIST,
            total: 0,
            pageNum: 1,
            pageSize: 10,
            tabPosition: '全部',
            RISKTYPE,
            show_tabs: true,
            streetArr: '',
            keyWord: '',
            status: '',
            timeRange: '',
            loading: false,
            tableData: [],
            statusOption: [
                { value: 1, label: '正在处理' },
                { value: 2, label: '已处理' },
                { value: -2, label: '超时未处理' }
            ],
            longitude: 116.397428, // 经度
            latitude: 39.90923, // 纬度,
            expandRowKeys: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            formLabelWidth: '70px',
            isSafe: false,
            safe: 0,
            textarea: '', // 处理情况描述
            orgId: '',
            orderBy: '',
            resonId: '',
            orgList: [],
            responseStatus: '',
            streetList: [],
            defaultProps: {
                value: 'streetId',
                label: 'streetName',
                children: 'streetNameListResultList',
                checkStrictly: true
            },
            // 风险日志
            showLog: false,
            riskId: null
        }
    },
    created() {
        this.selectRiskEventList()
        this.getStreetList()
    },
    methods: {
        // 获取组织列表
        getStreetList() {
            streetList().then(res => {
                res.data.forEach(item => {
                    item.streetNameListResultList.forEach(itemC => {
                        if (itemC.streetNameListResultList.length === 0) {
                            delete itemC.streetNameListResultList
                        }
                    })
                })
                this.streetList = res.data
            })
        },
        getContent,
        count(index) {
            return (this.pageNum - 1) * this.pageSize + index + 1
        },
        searchList() {
            this.pageNum = 1
            this.selectRiskEventList()
        },
        // 手风琴效果
        toogleExpand(row) {
            const $table = this.$refs.table
            this.tableData.map(item => {
                if (row.id !== item.id) {
                    $table.toggleRowExpansion(item, false)
                }
            })
            $table.toggleRowExpansion(row)
        },
        // 风险事件列表
        async selectRiskEventList() {
            this.loading = true
            const data = {
                bed: 2,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                condition: this.keyWord,
                riskEventType: this.tabPosition === '全部' ? '' : Number(this.tabPosition),
                orderBy: this.orderBy,
                processStatus: this.status,
                streetId: this.streetArr.length > 0 ? this.streetArr[0] : '',
                deptId: this.streetArr.length > 1 ? this.streetArr[1] : '',
                strTime: this.timeRange ? this.timeRange[0] : '',
                endTime: this.timeRange ? this.timeRange[1] : ''
            }
            const res = await selectRiskEventList(data)
            this.loading = false
            this.tableData = res.data.rows
            this.tableData.forEach(item => {
                item.responderList = item.responder ? item.responder.split(',') : []
                item.responderPhoneList = item.responderPhone ? item.responderPhone.split(',') : []
            })
            this.total = res.data.total
        },
        // 下载表格数据
        async downLoadTable() {
            const data = {
                bed: 2,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                condition: this.keyWord,
                riskEventType: this.tabPosition === '全部' ? '' : Number(this.tabPosition),
                orderBy: this.orderBy,
                processStatus: this.status,
                streetId: this.streetArr.length > 0 ? this.streetArr[0] : '',
                deptId: this.streetArr.length > 1 ? this.streetArr[1] : '',
                strTime: this.timeRange ? this.timeRange[0] : '',
                endTime: this.timeRange ? this.timeRange[1] : ''
            }
            const res = await riskEventListPageDown(data)
            downloadStream(res, '智慧居家安全信息.xls')
        },
        // 翻页
        handleCurrentChange(val) {
            this.loading = true
            this.pageNum = val
            this.selectRiskEventList()
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.selectRiskEventList()
        },
        // 风险类型点击
        handleClick(value) {
            if (value === 2 || value === 4) {
                this.$message.info('该功能暂未上线，敬请期待！')
                return false
            }
            this.tabPosition = value
            this.resonId = ''
            this.pageNum = 1
            if (this.tabPosition !== '全部') {
                this.show_tabs = false
            } else {
                this.show_tabs = true
            }
            this.loading = true
            this.selectRiskEventList()
        },
        sortChangeInit(column, prop, order) {
            if (column.prop === 'alertTime') {
                if (column.order === 'ascending') {
                    this.orderBy = 1
                } else if (column.order === 'descending') {
                    this.orderBy = 0
                } else {
                    this.orderBy = null
                }
            } else if (column.prop === 'responseTime') {
                if (column.order === 'ascending') {
                    this.orderBy = 3
                } else if (column.order === 'descending') {
                    this.orderBy = 2
                } else {
                    this.orderBy = null
                }
            }
            this.pageNum = 1
            this.loading = true
            this.selectRiskEventList()
        },
        // 查看风险日志
        async seeLog(row) {
            this.riskId = row.id
            this.showLog = true
        }
    }
}
</script>

<style lang="scss" scoped>
.riskBox {
    // margin: 20px;
    background: #fff;
    min-height: calc(100vh - 130px);

    .tabs {
        padding: 20px 5px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .menuTabs {
            display: flex;
            margin-bottom: 1%;

            .tabItem {
                padding: 10px 15px;
                background: #f5f5f5;
                font-size: 14px;
                color: #606266;
                cursor: pointer;
            }

            .tabItem:hover {
                color: #3fcf89;
            }

            .active-tab {
                background: #3fcf89;
                border-radius: 4px;
                color: #fff;
            }

            .active-tab:hover {
                color: #fff;
            }
        }
    }
}

.table {
    .demo-table-expand {
        width: 100%;
    }

    .map {
        width: 100%;
        height: 400px;
        background-color: #fff;
        position: relative;
        overflow: hidden;
        background-color: green;
        border: 1px solid black;
    }
}

.date-box {
    display: flex;
    height: 40px;
    align-items: center;

    .date-txt {
    }

    .num-box {
        color: orange;
        margin-top: -10px;
        margin-left: 5px;
        font-family: DIN;

        i {
            font-size: 12px;
        }

        span {
            font-size: 18px;
            font-weight: bold;
            font-style: italic;
        }
    }
}

.processStatus {
    display: flex;
    align-items: center;

    img {
        margin-right: 5px;
    }
}

/deep/ .el-radio-button__inner:hover {
    color: #3fcf89;
}

/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #fff;
}
</style>
